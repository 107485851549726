import * as React from "react";
import { taskEither } from "fp-ts";
import { login, redirectToCodec } from "../routes/user";
import { pipe } from "fp-ts/function";
import { rightOrThrow } from "../utils/fp";
import { useSupabase } from "../utils/supabase/useSupabase";
import { useRouter } from "next/router";
import { useGlobalAlertsSubject } from "../components/common/GlobalAlerts";
import { messages } from "../utils/i18n/i18n";
import { useTranslation } from "../utils/i18n/lang";
import { reportError } from "../utils/reporting";

export const useLogout = () => {
    const supabase = useSupabase();
    const router = useRouter();
    const alerts$ = useGlobalAlertsSubject();
    const t = useTranslation(msg);

    return pipe(
        taskEither.tryCatch(() => supabase.auth.signOut(), String),
        taskEither.chain(() =>
            taskEither.tryCatch(
                () =>
                    router.push(
                        login.route({
                            redirectTo: pipe(
                                router.asPath,
                                redirectToCodec.decode,
                                rightOrThrow
                            ),
                        })
                    ),
                String
            )
        ),
        taskEither.mapLeft((e) => {
            reportError("logout failed", e);
            alerts$.next({
                severity: "error",
                message: t.logoutFailedMsg,
            });
        })
    );
};

const msg = messages(
    {
        logoutFailedMsg:
            "Logout failed. Error has been reported to administrators.",
    },
    {
        cs: {
            logoutFailedMsg:
                "Odhlášení se nepodařilo. Problém byl reportován administrátorům.",
        },
    }
);
