import { Apply1 } from "fp-ts/Apply";
import { pipe } from "fp-ts/function";
import { option } from "fp-ts";

export interface QueryError {
    kind:
        | "network"
        | "unexpectedResponseFormat"
        | "unexpectedDataFormat"
        | "dbError"
        | "unknown";
    message: string;
}

interface LoadingState {
    type: "loading";
}
export const isLoadingState = <A>(fa: QueryState<A>): fa is LoadingState =>
    fa.type === "loading";

interface ErrorState extends QueryError {
    type: "error";
}
export const isErrorState = <A>(fa: QueryState<A>): fa is ErrorState =>
    fa.type === "error";

interface CompletedState<Data> {
    type: "completed";
    data: Data;
    // totalCount: number; TODO needed? is possible to get it from supabase
}
export const isCompletedState = <A>(
    fa: QueryState<A>
): fa is CompletedState<A> => fa.type === "completed";

export type QueryState<Data> = ErrorState | CompletedState<Data> | LoadingState;

export const queryStateData = <Data>(state: QueryState<Data>) =>
    pipe(
        state,
        option.fromPredicate(isCompletedState),
        option.map((state) => state.data)
    );

export function completed<A>(a: A): QueryState<A> {
    return { type: "completed", data: a };
}

const map: <A, B>(fa: QueryState<A>, f: (a: A) => B) => QueryState<B> = (
    fa,
    f
) => (isCompletedState(fa) ? completed(f(fa.data)) : fa);

const ap: <A, B>(
    fab: QueryState<(a: A) => B>,
    fa: QueryState<A>
) => QueryState<B> = (fab, fa) =>
    isCompletedState(fab)
        ? isCompletedState(fa)
            ? completed(fab.data(fa.data))
            : fa
        : fab;

export const URI = "QueryState";
export type URI = typeof URI;

declare module "fp-ts/lib/HKT" {
    interface URItoKind<A> {
        readonly QueryState: QueryState<A>;
    }
}

export const queryState: Apply1<URI> = {
    URI,
    ap,
    map,
};
