import * as React from "react";
import Layout from "../components/xathlo/Layout";
import { LogoXathlo } from "../components/LogoXathlo";
import { useUser } from "../utils/supabase/useUser";
import { pipe } from "fp-ts/lib/function";
import { option } from "fp-ts";
import { Badge, Button, Container, Typography } from "@mui/material";
import { AccountBtn } from "../components/user/AccountBtn";
import { InternalLink } from "../components/common/Link";
import { dashboardRoute, login, redirectToCodec } from "../routes/user";
import { rightOrThrow } from "../utils/fp";
import LoginIcon from "@mui/icons-material/Login";

// https://www.kiwi.com/cz/pages/content/privacy
//   https://drinkit.cz/gdpr
// https://www.hithit.com/cs/article/privacyPolicy
export default () => {
    return (
        <Layout rightMenu={<LoginBtn />}>
            <Container
                maxWidth={"sm"}
                sx={{
                    py: 10,
                }}
            >
                <Badge badgeContent={"ALPHA"} color={"primary"}>
                    <LogoXathlo width={300} />
                </Badge>
                <Typography variant={"h2"} component={"h1"} sx={{ pt: 5 }}>
                    aplikace pro správu sportovních událostí
                </Typography>
            </Container>
        </Layout>
    );
};

const LoginBtn = () => {
    const user = useUser();
    return pipe(
        user,
        option.fold(
            () => (
                <InternalLink
                    href={login.route({
                        redirectTo: pipe(
                            dashboardRoute.route({}).pathname,
                            redirectToCodec.decode,
                            rightOrThrow
                        ),
                    })}
                >
                    <Button startIcon={<LoginIcon />} variant={"text"}>
                        Přihlásit se
                    </Button>
                </InternalLink>
            ),
            () => <AccountBtn />
        )
    );
};
