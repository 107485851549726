import * as t from "io-ts";
import { UrlObject } from "url";
import { NullableFields } from "../types";
import { flow, pipe } from "fp-ts/function";
import { array, option, readonlyArray, string } from "fp-ts";
import { subdomainsDir } from "../../const";
import { NextRouter, useRouter } from "next/router";
import { sequenceS } from "fp-ts/Apply";
import { ParsedUrlQueryInput } from "querystring";
import { Option } from "fp-ts/Option";
import { resolveHref } from "next/dist/client/resolve-href";

export const defineRoute = <QueryC extends t.Mixed>(
    queryCodec: QueryC,
    route: (q: NullableFields<t.TypeOf<QueryC>>) => UrlObject // Nullable -> in dynamic routing you do not have all parameters during SSR
) => ({
    queryCodec,
    route,
});
export type Route = ReturnType<typeof defineRoute>; // TODO derived type is not generic, define manually before defineRoute
export type Query<R extends Route> = t.TypeOf<R["queryCodec"]>;

export const defaultAs = (pathname: string) =>
    pipe(
        pathname,
        option.fromNullable,
        option.filter((path) => path.startsWith(subdomainsDir)),
        option.map(
            flow(
                string.split("/"),
                readonlyArray.filter((segment) => segment != ""), // cleans starting ending /
                readonlyArray.dropLeft(2), // /[_subdomains]/snek/rest/ofRoute
                (segments) => "/" + segments.join("/")
            )
        )
    );

export const useRouteQuery = <R extends Route>(route: R): Option<Query<R>> => {
    const router = useRouter();
    return pipe(router.query, route.queryCodec.decode, option.fromEither);
};

const resolvedAsCodec = t.tuple([t.string, t.string]);

export const resolveAs = (href: UrlObject, router: NextRouter) =>
    pipe(
        resolveHref(router, href, true),
        resolvedAsCodec.decode,
        option.fromEither,
        option.map((res) => res[1])
    );

export const urlObjectAs = (href: UrlObject, router: NextRouter) =>
    pipe(resolveAs(href, router), option.chain(defaultAs), option.toUndefined);

// use in org web section
export const shallowPush = (href: UrlObject, router: NextRouter) => {
    router.push(href, urlObjectAs(href, router), {
        shallow: true,
    });
};

export const replaceQuerySegment = (url: UrlObject): UrlObject => {
    const query = pipe(
        url.query,
        option.fromNullable,
        option.chain(flow(t.UnknownRecord.decode, option.fromEither))
    );
    const segments = pipe(
        url.pathname,
        option.fromNullable,
        option.map((pathname) => pathname.split("/"))
    );

    return pipe(
        { query, segments },
        sequenceS(option.Apply),
        option.fold(
            () => url,
            (ctx) => {
                const segmentsNew = [...ctx.segments];
                const queryNew = { ...ctx.query };

                pipe(
                    ctx.segments,
                    array.mapWithIndex((i, segment) => {
                        if (segment.startsWith("[") && segment.endsWith("]")) {
                            const key = segment.slice(1, -1);
                            if (ctx.query[key]) {
                                segmentsNew[i] = `${ctx.query[key]}`;
                                delete queryNew[key];
                            }
                        }
                    })
                );

                return {
                    ...url,
                    pathname: segmentsNew.join("/"),
                    query: queryNew as ParsedUrlQueryInput,
                };
            }
        )
    );
};

type QueryArrayInput<T> = T | Array<T>;
type QueryArray<T> = Array<T>;

/**
 * deals with different interpretation of query parameter array parsing
 * (parsed as array only if parameter occur more than once)
 */
export const queryArrayCodec = <C extends t.Mixed>(codec: C) => {
    const arrayCodec = t.array(codec);

    return new t.Type<QueryArray<t.TypeOf<C>>, QueryArrayInput<t.InputOf<C>>>(
        `QueryArray<${codec.name}>`,
        arrayCodec.is,
        (input, ctx) =>
            pipe(
                input,
                (i) => (t.UnknownArray.is(i) ? i : [i]), // if single value is passed, wrap it and all hard work is done by arrayCodec
                (i) => arrayCodec.validate(i, ctx)
            ),
        arrayCodec.encode
    );
};
