import * as React from "react";

export const useMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const handleOpenBtnClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const onClose = () => {
        setAnchorEl(null);
    };

    return {
        menuProps: {
            anchorEl,
            open: anchorEl != null,
            onClose,
        },
        handleOpenBtnClick,
        close: onClose,
    };
};
