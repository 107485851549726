import * as React from "react";
import { useTranslation } from "../../utils/i18n/lang";
import { useMenu } from "../common/useMenu";
import { useLogout } from "../../hooks/useLogout";
import {
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import { messages } from "../../utils/i18n/i18n";
import { dashboardRoute } from "../../routes/user";
import { useRouter } from "next/router";

export const AccountBtn = () => {
    const m = useTranslation(msg);
    const menu = useMenu();
    const handleLogout = useLogout();
    const router = useRouter();

    return (
        <>
            <IconButton onClick={menu.handleOpenBtnClick}>
                <AccountCircleIcon />
            </IconButton>
            <Menu
                {...menu.menuProps}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuItem
                    onClick={() => {
                        router.push(dashboardRoute.route({}));
                        menu.close();
                    }}
                >
                    <ListItemIcon>
                        <AccountCircleIcon />
                    </ListItemIcon>
                    <ListItemText>{m.home}</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText>{m.logout}</ListItemText>
                </MenuItem>
            </Menu>
        </>
    );
};

const msg = messages(
    {
        logout: "Logout",
        home: "My account",
    },
    {
        cs: {
            logout: "Odhlásit",
            home: "Můj účet",
        },
    }
);
